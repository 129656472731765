.website-detail-box{
    display: flex;
    justify-content: center;
    width: 100%;
}

.website-detail-section{
    width: 50%;
    padding: 5px;
}

.website-action-headers{
    display: flex;
    justify-content: flex-end;
}

.website-details-row{
    display: flex;
    padding: 10px;
}

.website-details-row-item{
    padding-right: 5px;
    padding-left: 5px;
}

.website-create-conatiner{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}


.website-settings{
    display: flex;
    align-items: center;
}

.website-settings-item{
    padding: 5px;
}