.applist-container{
    padding: 10px;
}

.applist{
    display: flex;
    padding: 10px;

}
.app-tile{
    text-align: center;
    cursor: pointer;
}

.app-details{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

