.create-motors-container{
    width: 100%;
    z-index: 101;
    position: absolute;
    inset: 0;
    background: white;
    margin: 5px;
}

.create-motors-container-loop{
    width: 100%;
}

.motor-type-button{
    text-align: center;
}

.create-motors-headers{
    display: flex;
    background-color: #eee;
    height: 60px;
    align-items: center;
    justify-content: space-between;
}

.motor-header-close{
    padding: 5px;
    cursor: pointer;
}



.api-endpoint{
    display: flex;
    align-items: flex-end;
}

.from-input-settings{
    padding: 10px;
    justify-content: center;
}

.from-input-setting{
    margin: 10px;
}

.pipeline-container{
    text-align: center;
}

.stage-container{
    width: 100%;
}

.stage-header{
    display: flex;
    width: 60%;
    height: 50px;
    background-color: #eee;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 2px solid;
}

.stage-header-container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.stage-app-container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.stage-app{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
    padding: 10px;
}
.vertical-seperator{
    height: 10px;
    width: 2px;
    background: black;
}

.vertical-seperator-container{
    display: flex;
    justify-content: center;
}

.stage-action-buttons{
    cursor: pointer;
}

.motors-body{
    padding: 10px;
    position: relative;
    display: block;
    min-height: 1024px;
    overflow: auto;

}


/* .motor-tile-body{
    border: 1px solid #eee;
    padding: 10px;
    min-height: 500px;
    min-width: 500px;
    margin-top: auto;
    margin-bottom: auto;
    overflow: scroll;
} */

.motor-next-divider{
    display: flex;
    align-items: center;
}

.motor-body-tile{
    display: flex;
    align-items: center;
}


.motor-divider-line{
    width: 30px;
    height: 2px;
    background: black;
}


.motor-type-header{
    height: 50px;
    background: #eee;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.motor-type-name{
    padding: 3px;
}

.motor-full-screen{
    position: absolute;
    z-index: 600;
    inset: 0px;
}

.motor-full-screen-container{
    width: 100%;
    background: white;
    min-height: 1024px;
}

.motor-full-screen-headers{
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.full-screen-close{
    cursor: pointer;
}



.pipeline-row-even{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.pipeline-row-odd{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
.motor-divider-line-last-container{
    display: flex;
    justify-content: center;
}

.motor-next-divider-last{
    text-align: center;
}

.motor-divider-line-last{
    height: 30px;
    width: 2px;
    background: black;
}


.type-header{
    display: flex;
}

.stage-type-container{
    display: flex;
    justify-content: space-between;
}

.empty-stage{
    display: flex;
    align-items: center;
}

.empty-stage-body{
    border: 1px solid transparent;
    padding: 10px;
    min-height: 500px;
    margin-top: auto;
    margin-bottom: auto;

}

.add-icon{
    cursor: pointer;
}

.pipeline-columns{
    align-items: center;
}

.pipeline-name{
    padding: 10px;
    display: flex;
    justify-content: space-between;   
}