.workspaces-container{
    margin: 20px;
}

.workspaces-items{
    display: flex;
    border: 1px solid #eee;
    border-radius: 4px;
    justify-content: space-between;
    padding: 10px;
    margin: 10px;
    align-items: center;
}   

.workspace-owner-detail{
    display: flex;
    align-items: center;
}

.material-symbols-outlined.write{
    color: #3A07CD;
}