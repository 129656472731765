
.style-classes-chooser{
    padding: 4px;
    border: 1px solid #eee;
    cursor: pointer;
}

.style-classes-chooser.active{
    border: 1px solid #3A07CD;
}


.step-headers{
    padding: 5px;
    border-bottom: 1px solid #eee;
}


.step-headers.active{
    border-bottom: 1px solid #3A07CD;
}

.scsytem-container{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.scsytem-container:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.scsytemclass-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.scsystem-add{
    display: flex;
    justify-content: center;
    width: 100%;
}