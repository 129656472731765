.stage-container{
    /* height: 500px;
    overflow: auto;
    border: 1px solid #eee; */
}

.stage-container.disabled{
    /* border: 4px dashed #eee; */
}

.stage-inner-container{
    padding: 10px;
}

.stage-type-container-header{
    display: flex;
    justify-content: space-between;
    background-color: #eee;
    align-items: center;
}
.stage-app-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.stage-app-name-container{
    padding: 10px;
}

.stage-app-change-container{
    padding: 10px;
}

.stage-type-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stage-return-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.stage-action-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.stage-params{
    padding: 10px;
    border-bottom: 1px solid #eee;
}



.declare-variable-disabled{
    pointer-events: none;
}


.stage-continue{
    padding: 10;
}

.continue-input{
    display: flex;
    justify-content: center;
}