.container{
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    border: '1px solid #ccc';
    border-radius: '8px';
    padding: '16px';
    max-width: '200px';
    box-shadow: '0 4px 8px rgba(0, 0, 0, 0.1)';
}

.tile {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 20px;
    width: 200px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
}
  
  .property-level-1 {
    margin-bottom: 10px;
    font-size: 12px;
  }
  
  .property-level-2 {
    margin-bottom: 10px;
    font-size: 10px;
  }
  .main-property {
    /* font-weight: bold; */
    color: #007bff;
    display: flex;    
    align-items: center;
  }
  
  /* .main-property:hover {
    text-decoration: underline;
  } */

  .import-template-button{
    margin-left: 4px;
    margin-top: 10px;
    padding: 4px;
    border-radius: 4px;
    color: rgb(2, 167, 112);
    border: 1px solid #eeee;
    font-size: 10px;
    cursor: pointer;
  }

  .import-template-button:hover{
    background-color: #e0e0e0;
  }
  

  .clone-template-button{
    margin-left: 4px;
    /* margin-right: 8px; */
    margin-top: 10px;
    padding: 4px;
    border-radius: 4px;
    color: red;
    border: 1px solid #eeee;
    font-size: 10px;
    cursor: pointer;
  }

  .clone-template-button:hover{
    background-color: #e0e0e0;
  }

  .template-action-buttons{
    display: flex;
  }