.app-list{
    display: flex;
    max-height: 500px;
}

.app-tile{
    padding: 10px;
    margin: 10px;
    border: 1px solid #eee;
}

.app-list-wrapper-container{
    min-width: 500px;
}

.app-list-wrapper{
    display: grid;
    margin-top: 10px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

}

.toiler-app-block{
    display: flex;
}

.toiler-app-block-item{
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.toiler-app-block-item.active{
    border-bottom: 1px solid #3A07CD;
}