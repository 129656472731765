.usage-filter-box{
    padding: 10px;
}

.usage-filter{
    display: flex;
    align-items: center;
}

.usage-headers{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.usage-containers{
    padding: 20px;
}

.pipeline-http-usage{
    display: flex;
    margin: 20px;
    align-items: center;
}

.usage-label{
    padding: 10px;
}