.menubar-item{
    display: flex;
    padding: 10px;
    margin: 10px;
    /* border: 2px solid #eee; */
    border-radius: 4px;
    background: #3A07CD;
    color: #fff;
    text-decoration: none;
    padding-left: 30px;
    padding-right: 30px;
}

.menubar-list{
    height: 100%;
    border-right: 1px solid #eee;
}
