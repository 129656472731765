.code-nav-section-headers{
    display: flex;
    padding: 5px;
    font-size: 12px;
    font-weight: 800;
}

.code-nav-container{
    min-width: 200px;
    padding: 10px;
}

