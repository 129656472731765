.files-input-display{
    
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    word-break: break-all;
    white-space: nowrap;
}

.files-input-display:hover{
    overflow: visible; 
    white-space: normal;
    height:auto; 
}