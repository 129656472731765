.dashboard-container{
}

.menu{
    min-width: 150px;
    min-height: 1024px;
}
  
.container{
    width: 100%;
}
  
.app-header-menu{
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}

.app-main-container{
    display: flex;
   
  }