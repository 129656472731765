.output-container{
    padding: 10px;
    border: 1px solid #eee;   
}

.insert-output-data-tile{
    display: flex;
    margin: 2px;
    cursor: pointer;
}

.insert-output-data-tile.active{
    border: 1px solid #3eccf0;
}

.insert-output-data-tile.active.disabled{
    border: 1px solid #eee;
}

/* .insert-data-tile:hover{
    
} */

.field-stack{
    width: 500px;
    border: 1px solid #eee;
}

.insert-data-container{
    width: 98%;
    border: 1px solid #eee;
    margin-top: 2px;
}

.insert-data-container-header{
    display: flex;
    justify-content: flex-end;
}

.insert-data-outer-container{
    display: flex;
    width: 100%;
    justify-content: center;
}

.data-type{
    font-size: 14px;
    padding: 2px;
    border-radius: 4px;
    border: 1px solid #eee;
    margin: 2px;
}

.data-label{
    padding: 4px;
    border-radius: 8px;
    border: 1px solid #eee;
}
.insert-data-tile-header{
    display: flex;
    justify-content: space-between;
}

