.condition-tile{
    min-width: 200px; 
    min-height: 200px; 
    border: 1px solid black; 
    margin: 5px;
    cursor: pointer;
}

.condition-tile-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border: 1px solid #eee;
}

.condition-tile-stages{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin: 2px;
    border: 1px solid #eee;   
}