.element-nav-container.active{
    /* border: 1px solid #3A07CD; */
    box-shadow: 0 4px 8px 0 #3a07cd33;
}

.element-nav-container.focus{
    /* border: 1px dotted #3A07CD; */
    box-shadow: 0 4px 8px 0 #00000033;
}

.element-nav-container{
    display: flex;
    cursor: pointer;
    align-items: center;
}

.add-element-container{
    margin-top: 8px;
    display: flex;
    justify-content: center;
}

.element-details{
    display: flex;
    align-items: center;
}

.elements-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.element-sources{
    display: flex;
}

.element-header-tiles{
    padding: 5px;
    margin-right: 5px;
    border-bottom: 2px solid #eee;
    cursor: pointer;
}

.element-header-tiles.active{
    border-bottom: 2px solid #3A07CD;
}

.elements-container-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 200px));
    
}

.elements-container-wrapper-templates{
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-top: 10px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    height: 450px;
    flex-grow: 1;
}

.element-gallery-tiles{
    cursor: pointer;
    border: 2px solid #eee;
    margin: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toiler-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
  }