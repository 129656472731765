.input-fields{
    display: flex;
}

.save-button-block{
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    margin: 5px;
}

.input-list{
    width: 50%;
    padding: 10px;
}

.input-form{
    width: 50%;
    padding: 10px;
}

.input-fields-container{
    display: flex;

}

.preview-input-container{
    padding: 10px;
}

.schema-inputs{
    padding: 10px;
}

.schema-action-buttons{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.schema-action-button{
    padding: 10px;
}
