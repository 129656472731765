.main-container{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #ffffff;
    z-index: 100;
    overflow: scroll;
}


.preview-header{
    width: 100%;
    height: 20px;
    background-color: black;
    opacity: 0.9;
    display: flex;
    justify-content: flex-end;
}