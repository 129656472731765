.androidapp-detail-box{
    display: flex;
    justify-content: center;
    width: 100%;
}

.androidapp-detail-section{
    width: 50%;
    padding: 5px;
}

.androidapp-action-headers{
    display: flex;
    justify-content: flex-end;
}

.androidapp-details-row{
    display: flex;
    padding: 10px;
    align-items: center;
}

.androidapp-details-row-item{
    padding-right: 5px;
    padding-left: 5px;
}

.androidapp-create-conatiner{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}


.androidapp-settings{
    display: flex;
    align-items: center;
}

.androidapp-settings-item{
    padding: 5px;
}

.uploadkeystoreitems{
    padding: 5px;
    display: flex;
    align-items: center;
}

.version-conatiner{
    position: relative;
}

.history-container{
    position: absolute;
}