.create-option{
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.create-option.active{
    border-bottom: 1px solid #3A07CD;
}

.app-market-place-grid{
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-top: 10px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    height: 450px;
    flex-grow: 1;
}

.new-create-box{
    margin-top: 50px;
}