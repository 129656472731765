.schema-list{
    padding: 10px;
    border: 1px solid #eee;
}

.schema-list-container{
    padding: 10px;
    width: 60%;
}

.app-main-settings{
    width: 40%;
}

.schema-item{
    display: flex;
    border: 1px solid #eee;
    border-radius: 4px;
    justify-content: space-between;
    padding: 10px;
    margin: 10px;
    align-items: center;
}

.schema-headers{
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.schema-create-input{
    padding: 10px;

}

.schema-create-buttons{
    padding: 5px;
    display: flex;
}

.schema-main-container{
    display: flex;
    width: 100%;
}

.publish-block{
    display: flex;
    align-items: center;
    padding: 10px;
}
