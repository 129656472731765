.readme-header{
    display: flex;
    padding: 5px;
    background-color: #eee;
    justify-content: space-between;
    align-items: center;
}

.fullscreen-container{
    display: flex;
    width: 100%;
}

.fullscreen-editor{
    width: 50%;
}

.fullscreen-display{
    width: 50%;
    margin-left: 20px;
}