.json-main-container{
    background-color: #fff;
    margin: 2px;
    width: 100%;
    display: flex;
    width: 200px;
    align-items: center;
    overflow-y: visible;
    
}

.json-value-container{
    overflow: hidden;
    cursor: pointer;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
}