/* .text-template-container{
    display: flex;
} */


.text-template-headers{
    display: flex;
    justify-content: space-between;
    padding: 5px;
    background-color: #eee;
    margin-bottom: 10px;
}