.sort-table-headers {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100px;
    justify-content: space-around;
}

.sort-table-headers:hover{
    background-color: #eee;
}

/* .sort-table-direction{
    padding: 4px;
} */
