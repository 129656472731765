.stage-container{
    /* height: 500px;
    overflow: auto;
    border: 1px solid #eee; */
}



.stage-body{
    min-height: 500px;
    overflow: auto;
    border: 1px solid #eee;
}

.stage-fullscreen-stage{
    width: 100%;
    z-index: 102;
    position: fixed;
    top: 65px;
    left: 315px;
    background: white;
    /* margin: 5px; */
}

.stage-container.disabled{
    /* border: 4px dashed #eee; */
}

.stage-inner-container{
    padding: 10px;
}

.stage-type-container-header{
    display: flex;
    justify-content: space-between;
    background-color: #eee;
    align-items: center;
}
.stage-app-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.stage-app-name-container{
    padding: 10px;
}

.stage-app-change-container{
    padding: 10px;
}

.stage-type-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stage-return-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.stage-action-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.stage-params{
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.stage-params-header{
    display: flex;
    padding: 5px;
    background-color: #eee;
    justify-content: space-between;
}

.declare-variable-disabled{
    pointer-events: none;
}

.stage-info{
    display: flex;
}

.stage-status{
    display: flex;
}

.sync{
    color:#3A07CD;
}

.loading{
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
.error{
    color: red;
}
.stage-continue{
    padding: 10;
}



.continue-input{
    display: flex;
    justify-content: center;
}