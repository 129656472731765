.manifest-details-line{
    display: flex;
}

.manifest-favicon-details-line{
    display: flex;
    align-items: center;
}

.manifest-details-item{
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #eee;
    width: 100px;
}

.manifest-media{
    margin-top: 20px;
}

.manifest-favicon-item{
    padding: 10px;
}

.banner-tile{
    display:  flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 300px;
    padding: 10px;
    border: 1px solid #eee;
}

.manifest-details{
    margin-left: 10px;
    margin-top: 10px;
}

.manifest-action-buttons{
    margin-top: 10px;
}

.documentation-schemas{
    margin-top: 10px;
}

.manifest-banners-outer-box{
    display: flex;
    flex: 1;
    min-width: 0px;
}

.manifest-banners-box{
    display: block;
    overflow: auto;
    margin-top: 10px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    width: 1500px;
    flex-grow: 1;
}

.manifest-banners-box::-webkit-scrollbar{
    display: none;
}

.favicon-container{
    width: 200px;
    height: 200px;
}

