.test-stage-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10;
}

.test-stage-container{
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.test-stage-container-input{
    width: 45%;
    padding: 5px;
    
}

.test-stage-container-test{
    width: 10%;
    padding: 10px;
    align-items: center;
    display: flex;
}

.test-stage-container-output{
    width: 45%;
}