.client-settings-box{
    padding: 10px;
}

.client-settings-item{
    display: flex;
    border: 1px solid #eee;
    border-radius: 4px;
    justify-content: space-between;
    padding: 10px;
    margin: 10px;
    align-items: center;
}