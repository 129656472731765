.input-container{
    position: relative;
}

.input-fields{
    display: flex;
}

.save-button-block{
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    margin: 5px;
}

.input-list{
    width: 50%;
    padding: 10px;
}

.input-form{
    width: 50%;
    padding: 10px;
    max-width: 750px;
}

.input-fields-container{
    display: flex;

}

.preview-input-container{
    padding: 10px;
}

.schema-inputs{
    padding: 10px;
}

.schema-action-buttons{
    display: flex;
}

.input-container-headers{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
}

.schema-action-button{
    padding: 10px;
}

.schema-reports{
    min-height: 500px;
}