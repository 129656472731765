@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  @keyframes slideDown {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideUp {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideInLeft {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0); }
  }

  @keyframes slideInRight {
    0% { transform: translateX(100%); }
    100% { transform: translateX(0); }
  }

  @keyframes slideOutLeft {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
  }

  
  @keyframes slideOutRight {
    0% { transform: translateX(0); }
    100% { transform: translateX(100%); }
  }

  
  @keyframes zoomIn {
    0% { transform: scale(0.5); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
  }

  
  @keyframes zoomOut {
    0% { transform: scale(1); opacity: 1; }
    100% { transform: scale(0.5); opacity: 0; }
  }

  @keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @keyframes pulse {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.1); }
  }

  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
  }

  @keyframes shake {
    0%, 100% { transform: translateX(0); }
    25%, 75% { transform: translateX(-10px); }
    50% { transform: translateX(10px); }
  }

  @keyframes wobble {
    0%, 100% { transform: translateX(0); }
    25% { transform: rotate(-5deg); }
    50% { transform: rotate(5deg); }
    75% { transform: rotate(-5deg); }
  }

  @keyframes swing {
    20% { transform: rotate(15deg); }
    40% { transform: rotate(-10deg); }
    60% { transform: rotate(5deg); }
    80% { transform: rotate(-5deg); }
    100% { transform: rotate(0); }
  }

  @keyframes flip {
    0% { transform: rotateY(0); }
    100% { transform: rotateY(180deg); }
  }

  @keyframes flipIn {
    0% { transform: rotateY(90deg); opacity: 0; }
    100% { transform: rotateY(0); opacity: 1; }
  }

  @keyframes flipOut {
    0% { transform: rotateY(0); opacity: 1; }
    100% { transform: rotateY(90deg); opacity: 0; }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @keyframes glow {
    0%, 100% { box-shadow: 0 0 5px #fff; }
    50% { box-shadow: 0 0 20px #fff; }
  }

  @keyframes hoverFloat {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }

  
  @keyframes scaleUp {
    0% { transform: scale(1); }
    100% { transform: scale(1.5); }
  }

  @keyframes scaleDown {
    0% { transform: scale(1); }
    100% { transform: scale(0.5); }
  }

  @keyframes ripple {
    0% { transform: scale(0); opacity: 1; }
    100% { transform: scale(4); opacity: 0; }
  }

  @keyframes typing {
    0% { width: 0; }
    100% { width: 100%; }
  }

  @keyframes blinkingCursor {
    0%, 50% { opacity: 1; }
    100% { opacity: 0; }
  }

  @keyframes scroll {
    0% { transform: translateY(0); }
    100% { transform: translateY(-100%); }
  }

  @keyframes blurIn {
    0% { filter: blur(10px); opacity: 0; }
    100% { filter: blur(0); opacity: 1; }
  }

  @keyframes blurOut {
    0% { filter: blur(0); opacity: 1; }
    100% { filter: blur(10px); opacity: 0; }
  }
  
