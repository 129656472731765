
/* .declare-variable-container{
    margin-top: 10px;
} */
.more-actions-icon{
    display: flex;
    position: relative;
}

.data-action-toggles{
    position: absolute;
    background-color: white;
    padding: 5px;
    z-index: 102;
}

.data-action-toggles-button{
    padding: 2px;
}

.data-action-toggles-button.active{
    padding: 2px;
    border: 1px solid #3A07CD;
}

.more-actions-icon:hover{
    border: 1px solid #eee;
    border-radius: 25px;
}

.data-label-dec{
    /* display: flex;
    padding: 4px;
    border-radius: 8px;
    border: 1px solid #eee;
    align-items: center; */
    display: flex;
    padding: 4px;
    border-radius: 8px;
    /* border: 1px solid #eee; */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    align-items: center;
}

.data-label-dec.active{
    display: flex;
    padding: 4px;
    border-radius: 8px;
    border: 1px solid #3A07CD;
}

.insert-data-help{
    color: #ababab;
    padding: 5px;
}

.next-action-tile{
    display: flex;
    justify-content: flex-end;
}

.data-label{
    display: flex;
    align-items: center;
}

.decalare-variable-data-type{
    font-size: 14px;
    padding: 2px;
    border-radius: 4px;
    /* border: 1px solid #eee; */
    margin: 2px;
    padding: 4px;
    border-radius: 8px;
    /* border: 1px solid #eee; */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    align-items: center;
    margin-top: 10px;
    align-items: center;
}

.input-type-switch{
    display: flex;
}

.input-type{
    padding: 5px;
    cursor: pointer;
    border-bottom: 2px solid #d4d4d4;
    margin: 5px;
}

.input-type.active{
    border-bottom: 2px solid #3A07CD;
}

.insertschema-variable-container.horizontal{
    display: flex;
}

.insertschema-variable-container.vertical{
    display: block;
}


.add-subschema{

}

.designer-add-subschema{
    min-width: 300px;
}

.data-type{
    padding: 5px;
}

.data-type.avl{
    padding: 5px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

.data-example{
    margin-top: 10px;
}