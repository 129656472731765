.element-active{
    border: 1px solid #1ca4ff;
}

.toiler-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
  }

  .display-none{
    display: none;
  }