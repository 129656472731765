.enum-options{
    display: flex;
    align-items: center;
}

.form-editor{
    z-index: 101;
    background: #fff;
    width: 100%;
    position: absolute;
    inset: 0;
}

.form-editor-header{
    display: flex;
    justify-content: flex-end;
}

.input-options{
    padding: 10px;
}

.input-options-label{
    padding: 10px;
}

.input-specs{
    width: 50%;
}
.form-container{
    display: flex;
}

.input-preview{
    padding: 10px;
    width: 50%;
}

.enum-options-name{
    padding: 10px;
}

.relation-selects{
    padding: 10px;
}