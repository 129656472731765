.stage-test-headers{
    display: flex;
    padding: 10px;
    width: 100%;   
}

.request-comp{
    padding: 10px;
    cursor: pointer;
}

.request-comp.active{
    border-bottom: 1px solid blue;
}

.request-params-row{
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
}

.request-params-row-block{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2px;
}

.form-data-row{
    display: flex;
    align-items: center;
}

.form-data-row-block{
    padding: 10px;
}