.condition-line{
    display: flex;
    padding: 10px;
    align-items: center;
}

.sort-line{
    display: flex;
    padding: 10px;
    align-items: center;
}

.sort-item{
    margin: 10px;
}

.ellipsis {
    white-space: nowrap;       /* Prevent text from wrapping to the next line */
    overflow: hidden;          /* Ensure overflowing text is hidden */
    text-overflow: ellipsis;   /* Add ellipsis (...) at the end of the text */
    max-width: 150px;              /* Set a specific width for the container */
    display: inline-block;     /* Ensure the element respects the width */
}

.ellipsis:hover{
    white-space: normal;
    overflow: visible;
    height: auto;
}


