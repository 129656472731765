.stage-params-header-option{
    cursor: pointer;
    padding: 5px;
    background-color: #e7e1e1;
    margin: 10px;
    /* border: 1px; */
    border-radius: 8px;

}

.stage-params-header-option.active{
    border: 1px solid black;
}

.stage-params-header{
    display: flex;
    justify-content: flex-start;
    padding: 5px;
    background-color: #eee;
}