
/* .scsytem-container{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.scsytem-container:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
} */

.cssbox{
    box-shadow: 0px 4px 8px 0 rgba(0,0,0, 0.2);
}

.cssbox:hover{
    box-shadow: 0px 8px 16px 0 rgba(0,0,0, 0.2);
}

.cssbox-header{

    display: flex;
    justify-content: space-between;
    align-items: center;

}

.cssbox-styles{
    margin: 10px;
}

.style-box{
    display: flex;
    align-items: center;
    justify-content: center;
}


.addition-button{
    display: flex;
    justify-content: center;
}
