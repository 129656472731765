.applist{
    display: flex;   
}


.query-block-selector{
    display: flex;
}

.query-block-selection{
    padding: 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
}


.query-block-selection.active{
    border-bottom: 1px solid blue;
}

.query-block{
    margin: 10px;
    border-bottom: 1px solid #eee;
    padding: 10px;
}

.field-row{
    display: flex;
}

.field-row-block{
    margin: 5px;
    padding: 5px;
}

.field-select-input{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 150px;
}

.field-select-actions{
    align-items: center;
    display: flex;
}

.field-select-box{
    align-items: center;
    display: flex;
    border: 1px solid #eee;
}

.order-by-block-row{
    display: flex;
}

.order-by-row-block{
    padding: 10px;
}

.join-block-row{
    display: flex;
    align-items: center;
}



.join-row-block{
    padding: 10px;
}

.limit-block-row{
    display: flex;
}

.limit-row-block{
    padding: 10px;
}

.join-block{
    margin: 10px;
}

.order-by-block{
    margin: 10px;
}

.group-by-block{
    margin: 10px;
}

.select-block{
    margin: 10px;
}

.limit-block{
    margin: 10px;
}

.where-block{
    margin: 10px;
}

.group-by-block-row{
    display: flex;
    align-items: center;
}

.group-by-row-block{
    margin: 10px;
}


.inoperator-input-types{
    padding: 5px;
    border-bottom: 1px solid #eee;
    margin: 5px;
    cursor: pointer;
}

.inoperator-input-types.active{
    border-bottom: 1px solid blue;
}

.builder-types{
    display: flex;
}

.builder-types-item{
    padding: 5px;
    cursor: pointer;
    margin: 5px;
}
 .builder-types-item.active{
    border-bottom: 1px solid #3A07CD;
 }