.element-type-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.element-block-controls{
    display: flex;
}

.extra-add-button{
    display: flex;
    justify-content: center;
}

.extra-prop-item{
    display: flex;
}

.extra-prop-val{
    margin: 5px;
}

.props-container{
    padding: 10px;
}