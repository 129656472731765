
.breadcrumbs-container{
    display: block;
    padding: 2px;
    min-width: 100px;
}

.breadcrumbs-list{
    display: flex;
}

.breadcrumb-box{
    margin-right: 3px;
}