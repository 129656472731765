.app-designer-container{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(255,255,255,1);
    z-index: 10;
}

.app-designer-header{
    height: 40px;
    background-color: #3A07CD;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 5px;
}

.app-canvas-width{
    display: flex;
    align-items: center;
}

.app-main-canvas{
    display: flex;
}

.left-toolbar{
    display: flex;
    z-index: 100;
    position: fixed;
    height: 100%;
    overflow: auto;
}

.right-toolbar{
    display: flex;
    z-index: 100;
    position: fixed;
    right: 0px;
    height: 100%;
}

.left-toolbar-drawer{
    width: 50px;
    background: #fff;
}

.left-toolbar-collapse{
    display: block;
}

.left-toolbar-collapse.visible{
    border-right: 1px solid;
}

.right-toolbar-collapse{
    display: block;
}

.right-toolbar-collapse.visible{
    border-left: 1px solid;
}


.navigator-header-title{
    display: flex;
    justify-content: center;
    padding: 4px;
}

.navigator-header{
    display: flex;
    align-items: center;
    justify-content: center;
}



.right-toolbars{
    display: flex;
    justify-content: flex-end;
}

.properties-toolbar-header{
    display: block;
    background: #fff;
    z-index: 200;
    height: 100%;
}

.app-designer-toolbars{
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100%;
}

/* .right-tool{
    background: #fff;
    border-left: #eee;
    z-index: 200;
} */

.properties-tiles{
    padding: 2px;
    margin: 4px;
    border-bottom: 2px solid #eee;
    cursor: pointer;
}

.properties-tiles.active{
    border-bottom: 2px solid #3A07CD;
}

.app-designer-canvas{
    display: flex;
    justify-content: center;
    width: 100vi;
    background-color: rgba(51,51,51,0.7);
    height: 100vb;
}


.app-header-right-toolbar{
    display: flex;
}

.app-canvas{
    background-color: #fff;
    height: 100vb;
    z-index: 80;
}

.material-symbols-outlined.preview{
    color: #fff;
}

.material-symbols-outlined.bactive{
    color: #fff;
}



.code-nav-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.resize-right-tool-bar{
    width: 2px;
    cursor: e-resize;
    height: 100%;
}



/* @media (max-width: 500px) {
    div {
        flex-direction: column;
    }
}

@media (max-width: 500px){
    div {
        display: block;
    }
} */


