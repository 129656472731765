.condition-operators{
    display: flex;
}

.condition-operator{
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 10px;
    border: 1px solid #3A07CD;
}


.condition-line{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.close-button{
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid #eee;
    cursor: pointer;
}

.or-block-desc{
    display: flex;
    justify-content: space-between;
}

.condition-result-block{
    padding: 10px;
}