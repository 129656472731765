.dashboard-main-container{
    display: flex;
    padding: 10px;
}

.dashboard-tiles{
    padding: 10px;
    border: 1px solid #eee;
    width: 100px;
    margin: 10px;
}