.form-line-item{
    display: flex;
    padding: 5px;
}

.form-sub-line-item{
    display: flex;
}

.form-line-item-item{
    padding: 5px;
}

.installation-template-block{
    display: flex;
    align-items: center;
}

.installation-template-block-element{
    align-items: center;
    margin: 10px;
}
