.App {

}

.app-main-container{
  display: flex;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.material-symbols-outlined.active{
  color: #3A07CD
}

.material-symbols-outlined.error{
    color: red;
}

.MuiButtonBase-root {
    color: #3A07CD
}


.toiler-labels{
                color:rgba(0, 0, 0, 0.6);
                font-family:"Roboto","Helvetica","Arial",sans-serif;
                font-weight:400;
                font-size:1rem;
                line-height:1.4375em;
             }

.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}
  
  /* On mouse-over, add a deeper shadow */
.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.container{
  width: 100%;
}

.toiler-select-input{
    padding: 0px;
    padding-left: 5px;
}
  
