.workspace-details{
    display: flex;
}

.permission-select-box{
    display: flex;
}

.item-name{
    padding: 2px;
    margin-left: 10px;
}

.workspace-select{
    display: flex;
    align-items: center;
    background-color: #eee;
    margin: 5px;
}

.workspace-container{
    width: 100%;
}

.workspace-detail-item{
    display: flex;
    margin: 5px;
    align-items: center;
    background-color: #eee;
}

.workspace-detail-detail{
    margin-left: 20px;
    /* margin-bottom: 5px; */
}

.workspace-item-detail{
    display: flex;
    margin: 5px;
}

.workspace-subitems{
    margin: 2px;
    background-color: #eee;
}

.collaboration-container{
    margin: 10px;
}


.collaboration-headers{
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
}
/* .workspace-container{
    width: 400px;
} */