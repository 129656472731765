.website-list{
    padding: 10px;
    border: 1px solid #eee;
}

.website-headers{
    padding: 10px;   
}

.website-list-container{
    padding: 10px;
}

.website-item{
    display: flex;
    border: 1px solid #eee;
    border-radius: 4px;
    justify-content: space-between;
    padding: 10px;
    margin: 10px;
    align-items: center;
}

.create-website-items{
    padding: 10px;
}