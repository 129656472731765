.manifest-top-section{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;   
}

.manifest-logo{
    width: 200px;
    height: 200px;
}

.manifest-banner{
    width: 400px;
    height: 300px;
    display: flex;
}

.manifest-doc-container{
    display: flex;
    width: 100%;
    padding: 10px;
}

.manifest-doc-bar{
    width: 200px;
    border-right: 1px solid #eee;
}

.manifest-doc-display{
    width: 100%;
    overflow: auto;
    margin-left: 10px;
}

.doc-menubar-item{
    padding: 10px;
    margin-left: 10px;
    text-decoration: underline;
}

.doc-menubar-item:hover{
    cursor: pointer;
}

